import { GoogleLogin } from "@react-oauth/google";
import { Layout, Button, Form, Input } from "antd";
import { login } from "./slice";
import styles from "./LoggedIn.module.css";
import { useDispatch } from "react-redux";
import logo from "../../brand/Isologotype/samu_light_bckgrnd.png";
const { Header } = Layout;

const __ = (k) => k;

export default function LoginForm(props) {
  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
      </Header>
      <div className={styles.loginFormContainer}>
        <App />
      </div>
    </Layout>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const onFinish = (params) => {
    dispatch(login(params));

    return false;
  };

  return (
    <Form
      className={styles.loginForm}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <div className={styles.iconContainer}>
        <img src={logo} width={350} />
      </div>
      <div className="google-login-container">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            dispatch(login(credentialResponse))
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          size={'large'}
        />
      </div>
    </Form>
  );
};
