import React, { useEffect } from "react";
import Check from "./features/session/Check";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "antd/dist/antd.css";
import "./App.css";
import Loading from "./features/loading/Index";
import { useSelector } from "react-redux";
import {
  selectGlobalErrors,
  selectGlobalMessages,
  selectGlobalSuccess,
  selectIsLoading,
} from "./features/loading/slice";
import { message } from "antd";
import UserList from "./features/users/Index";
import AccountList from "./features/accounts/Index";

function App() {
  const isLoading = useSelector(selectIsLoading);
  const messages = useSelector(selectGlobalMessages);
  const success = useSelector(selectGlobalSuccess);
  const errors = useSelector(selectGlobalErrors);

  useEffect(() => {
    if (messages && messages.length) {
      messages.forEach((m) => message.info(m));
    }
  }, [messages]);

  useEffect(() => {
    if (success && success.length) {
      success.forEach((m) => message.success(m));
    }
  }, [success]);

  useEffect(() => {
    if (errors && errors.length) {
      errors.forEach((m) => message.error(m));
    }
  }, [errors]);

  if (isLoading) return <Loading />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/set-password/:passwordToken" element={<div>Set Password</div>} />
      </Routes>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Check>
            <Routes>
              <Route path="/" element={<div>Home</div>} />
              <Route path="/users" element={<UserList/>} />
              <Route path="/accounts" element={<AccountList/>} />
            </Routes>
          </Check>
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
