import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { getToken, getUser, setToken, onLogout } from "../../app/api";
import {
  showGlobalErrors,
  showGlobalSuccess,
  startLoading,
  stopLoading,
} from "../loading/slice";

const initialState = {
  status: "iddle",
  user: getUser(),
  token: getToken(),
  isLoading: false,
  errors: [],
  configFetched: false,
  config: {},
};

const __ = (k) => k;

export const getConfig = createAsyncThunk(
  "session/getConfig",
  async (payload, { dispatch, getState }) => {
    const response = await API("getConfig");
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    }

    return response;
  }
);

export const login = createAsyncThunk(
  "session/login",
  async ({ credential }, { dispatch }) => {
    dispatch(startLoading());
    let response;

    try {
      response = await API("login", { token: credential });

      if (response && response.errors) {
        dispatch(showGlobalErrors(response.errors));
      }

      dispatch(stopLoading());

      return response;
    } catch (error) {
      dispatch(showGlobalErrors([__("generic.errors.internalServerError")]));
      dispatch(stopLoading());

      throw error;
    }
  }
);

export const setPassword = createAsyncThunk(
  "session/setPassword",
  async ({ createPasswordToken, password }, { dispatch }) => {
    dispatch(startLoading());
    let response;

    try {
      console.log(createPasswordToken);
      response = await API("setPassword", { createPasswordToken, password });

      if (response && response.errors) {
        dispatch(showGlobalErrors(response.errors));
      } else {
        dispatch(showGlobalSuccess([__("generic.alerts.passwordUpdated")]));
      }

      dispatch(stopLoading());

      return response;
    } catch (error) {
      dispatch(showGlobalErrors([__("generic.errors.internalServerError")]));
      dispatch(stopLoading());

      throw error;
    }
  }
);

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;

      onLogout();
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        console.log(action)
        debugger
        if (action.payload.user && action.payload.token) {
          state.user = action.payload.user;
          state.token = action.payload.token;
          state.isLoggedIn = true;

          setToken(action.payload.token, action.payload.user);
        }
      })
      .addCase(getConfig.pending, (state, action) => {
        state.configFetched = true;
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.config = action.payload;
      });
  },
});

export const { logout } = sessionSlice.actions;
export const selectIsFetching = (state) => state.session.status === "loading";
export const selectIsLoggedIn = (state) => !!state.session.token;
export const selectUser = (state) => state.session.user;
export const selectConfigFetched = (state) => state.session.configFetched;
export const selectHasManagerAccess = (state) => {
  if (state.session.user && state.session.user.role) {
    return state.session.user.role === 2;
  }

  return false;
};

export const selectCanRecord = (state) => {
  if (state.session.user && state.session.user.role) {
    return state.session.user.role !== 0;
  }

  return false;
};
export default sessionSlice.reducer;
