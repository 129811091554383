import { useEffect, useState } from "react";
import Api from "../app/api";

export default function useQuery ({ collection, operation, query }) {
  if (!query) {
    query = {};
  }

  if (!operation) {
    operation = 'find';
  }

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api('query', { collection, operation, query });
        console.log(data);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (collection) {
      fetchData();
    }

    return () => {};
  }, []);

  return { data, loading, error };
}