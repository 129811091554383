import React from "react";
import QueryTable from "../queryTable/Index";
import moment from "moment";
import { Button } from "antd";

const Role = {
  COLLABORATOR: 0,
  SELLER: 1,
  MANAGER: 2
}

const RoleNames = {
  [Role.COLLABORATOR]: 'COLLABORATOR',
  [Role.SELLER]: 'SELLER',
  [Role.MANAGER]: 'MANAGER'
}

const UserList = () => {
  const columns = [
    {
      title: "_id",
      dataIndex: "_id",
      search: true
    },
    {
      title: "Name",
      dataIndex: "userFullname",
      mapper: i => i.name + ' ' + i.lastName,
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      search: true,
    },
    {
      title: "Account",
      dataIndex: "account",
      filter: true,
      search: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      filter: true,
      mapper: i =>  RoleNames[i.role],
      search: true,
    },
    {
      title: "Google Active Session",
      dataIndex: "activeGoogleSession",
      filter: true,
      mapper: i => {
        let activeGoogleSession = 'No';
        if (i.googleExpiration && moment(i.googleExpiration).isSameOrAfter(moment())) {
          activeGoogleSession = 'Yes';
        }

        return activeGoogleSession;
      }
    },
    {
      title: "Google V2",
      dataIndex: "isGoogleTokenMigrated",
      filter: true,
      mapper: ({ isGoogleTokenMigrated }) => isGoogleTokenMigrated ? 'Yes' : 'No',
    },
    {
      title: "Last Access",
      dataIndex: "lastAccess",
      filter: true,
      render: (_, i, c) => {
        return i.lastAccess ? moment(i.lastAccess).fromNow() : "";
      },
      mapper: i => i.lastAccess,
      sorter: {
        compare: (a, b, c, d) => {
          if (a.lastAccess && b.lastAccess) {
            return moment(a.lastAccess).isSameOrBefore(moment(b.lastAccess)) ? -1 : 1;
          } else if (!a.lastAccess) {
            return -1;
          } else if (!b.lastAccess) {
            return 1;
          }
        }
      }
    },
    {
      title: 'Actions',
      render: () => {
        return <Button>Disable</Button>
      }
    }
  ];

 
  const query = [
    {
      $match: {
        isSamuUser: false
      }
    },
    {
      
      $lookup: {
        from: 'accounts', // Name of the account collection
        localField: 'account',
        foreignField: '_id',
        as: 'accountInfo'
      }
    },
    {
      $lookup: {
        from: 'googleTokens', // Name of the account collection
        localField: '_id',
        foreignField: 'user',
        as: 'google'
      }
    },
    {
      $lookup: {
        from: 'usages', // Name of the account collection
        localField: '_id',
        foreignField: 'user',
        as: 'usage'
      }
    },
    {
      $lookup: {
        from: 'configs', // Name of the account collection
        localField: '_id',
        foreignField: 'user',
        as: 'config'
      }
    },
    {
      $unwind: '$accountInfo'
    },
    {
      $project: {
        _id: 1,
        name: 1,
        email: 1,
        lastName: 1,
        googleExpiration: { $arrayElemAt: ["$google.expiryDate", 0] },
        lastAccess: { $arrayElemAt: ["$usage.updatedAt", 0] },
        isGoogleTokenMigrated: { $ifNull: [{ $arrayElemAt: ["$config.settings.isGoogleTokenMigrated", 0] }, false] },
        'account': '$accountInfo.name',
        role: 1
      }
    }
  ];

  return <div style={{ padding: "25px"}}>
    <QueryTable title={'Users'} collection={'users'} query={query} operation={'aggregate'} columns={columns}/>
    {/* <QueryTable expandableComponent={i => <ExpandableComponent item={i}/>} title={'Users'} collection={'users'} query={query} operation={'aggregate'} columns={columns} mapper={mapper}/> */}
  </div>
};

export default UserList;
