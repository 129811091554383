import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_URL || "https://api.ms.samu.ai/admin";
const methods = {
  query: {
    path: "/query",
    method: "POST",
  },
  getConfig: {
    path: "/account/config",
    method: "GET",
  },
  getClients: {
    path: "/account/clients",
    method: "GET",
  },
  getCallTypes: {
    path: "/account/calltypes",
    method: "GET",
  },
  createCallType: {
    path: "/account/calltypes",
    method: "POST",
  },
  updateCallType: {
    path: "/account/calltypes",
    method: "PUT",
  },
  getTrackers: {
    path: "/meeting/trackers",
    method: "GET",
  },
  getAnalytics: {
    path: "/account/analytics",
    method: "POST",
  },
  getSpeakers: {
    path: "/account/speakers",
    method: "GET",
  },
  createUser: {
    path: "/account/user/new",
    method: "POST",
  },
  resetPassword: {
    path: ({ userId }) => `/account/user/${userId}/reset-password`,
    method: "POST",
  },
  createMeeting: {
    path: "/meeting",
    method: "POST",
  },
  loadMeeting: {
    path: "/meeting/detail",
    method: "POST",
  },
  loadAi: {
    path: ({ id }) => `/meeting/${id}/ai`,
    method: "GET",
  },
  requestAi: {
    path: ({ id }) => `/meeting/${id}/ai`,
    method: "POST",
  },
  searchMeetings: {
    path: "/meeting/search",
    method: "POST",
  },
  login: {
    path: "/login",
    method: "POST",
  },
  deleteUser: {
    path: "/account/user/delete",
    method: "DELETE",
  },
  getMeetingDetails: {
    path: ({ id }) => `/meeting/${id}`,
    method: "POST",
  },
  deleteMeeting: {
    path: ({ id }) => `/meeting/${id}`,
    method: "DELETE",
  },
  toggleUserAccess: {
    path: () => `/account/user/access`,
    method: "POST",
  },
  cancelMeeting: {
    path: ({ id }) => `/meeting/${id}/cancel`,
    method: "POST",
  },
  addComment: {
    path: ({ id }) => `/meeting/${id}/comment`,
    method: "POST",
  },
  getComments: {
    path: ({ id }) => `/meeting/${id}/comments`,
    method: "GET",
  },
  getMyMeetings: {
    path: `/me/meetings`,
    method: "POST",
  },
  loadNotifications: {
    path: `/me/notifications`,
    method: "GET",
  },
  notificationSeen: {
    path: `/me/notifications`,
    method: "POST",
  },
  getTeamMeetings: {
    path: `/meeting/team`,
    method: "POST",
  },
  setPassword: {
    path: "/password",
    method: "POST",
  },
  getLoginData: {
    path: "/me/integrations/google",
    method: "GET",
  },
  disconnectGoogle: {
    path: "/me/integrations/google/disconnect",
    method: "POST",
  },
  createLibrary: {
    path: () => `/library`,
    method: "POST",
  },
  getLibraries: {
    path: () => `/library/`,
    method: "GET",
  },
  getMeetingsByLibrary: {
    path: (libraryId) => `/library/${libraryId}/meetings`,
    method: "GET",
  },
  getLibrariesByMeeting: {
    path: (meetingId) => `/library/${meetingId}/libraries`,
    method: "GET",
  },
  updateLibrariesToMeeting: {
    path: (libraryId) => `/library/${libraryId}/meetings`,
    method: "POST",
  },
  removeMeetingFromLibrary: {
    path: (libraryId) => `/library/${libraryId}/meetings`,
    method: "DELETE",
  },
  markAsSeen: {
    path: ({ id }) => `/meeting/${id}/seen`,
    method: "POST",
  },
  generateAIReport: {
    path: ({ id, reportType }) => `/meeting/${id}/ai-report/${reportType}`,
    method: "POST",
  },
  getAIReport: {
    path: ({ id, reportType }) => `/meeting/${id}/ai-report/${reportType}`,
    method: "GET",
  },
  getHubspotIntegration: {
    path: `/account/integration/hubspot`,
    method: "GET",
  },
  getHubspotIntegrationFilters: {
    path: `/account/integration/hubspot/filters`,
    method: "GET",
  },
  updateHubspotIntegration: {
    path: `/account/integration/hubspot`,
    method: "PUT",
  },
};

export default async function Api(key, payload) {
  const method = methods[key];

  let path = method.path;
  if (typeof path === 'function') {
    path = path(payload);
  }

  const request = {
    method: method.method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }

  if (method.method !== 'GET') {
    request.data = payload;
  }

  try {
    const response = await axios({
      url: baseUrl + path,
      ...request,
    });
  
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      onLogout();
      document.location.reload();
    }
  }

}

export function isLoggedIn() {
  const isLoggedIn = !!localStorage.getItem("samu:loggedIn");

  return isLoggedIn;
}

export function setToken(token, user) {
  localStorage.setItem("samu:token", token);
  localStorage.setItem("samu:loggedIn", true);
  localStorage.setItem("samu:user", JSON.stringify(user));
}

export function getToken() {
  return localStorage.getItem("samu:token");
}

export function getUser() {
  return JSON.parse(localStorage.getItem("samu:user"));
}

export function onLogout() {
  localStorage.removeItem('samu:token');
  localStorage.removeItem('samu:loggedin');
  localStorage.removeItem('samu:user');
}