import { Layout, Menu, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./loading.module.css";

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

export default function Loading() {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading} style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} />
      </div>
    </div>
  );
}
