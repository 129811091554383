import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from '../features/session/slice';
import loadingReducer from '../features/loading/slice';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    loading: loadingReducer
  },
});
