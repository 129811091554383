import React, { useEffect, useState } from "react";
import { Input, Table, Typography } from "antd";
import useQuery from "../../hooks/useQuery";
import Loading from "../loading/Index";

const QueryTable = ({
  collection,
  query,
  columns,
  mapper,
  operation,
  title,
  expandableComponent
}) => {
  let { data, loading } = useQuery({
    collection,
    query,
    operation,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [qty, setQty] = useState(data?.length || 0);
  const filterableProps = columns.filter(c => c.search).map(c => c.dataIndex);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  if (!mapper) {
    mapper = (v) => v;
  }

  if (loading) {
    return <Loading />;
  }
  

  if (data) {
    data = data.map((item) => {
      const result = {
        key: item._id || item.id
      };

      for (const prop of columns) {
        if (prop.mapper) {
          result[prop.dataIndex] = prop.mapper(item);
        } else {
          result[prop.dataIndex] = item[prop.dataIndex];
        }
      }

      return result;
    });
  }

  const dbColumns = columns.map(({ title, dataIndex, filter, ...other }) => {
    const item = {
      title,
      dataIndex,
      sorter: (a, b) => a[dataIndex] - b[dataIndex],
      ...other
    };

    if (filter) {
      const values = [...new Set(data.map((i) => i[dataIndex]))].sort((a, b) =>
        a < b ? -1 : 1
      );
      item.filters = values.map((v) => ({ text: v, value: v }));
      item.onFilter = (value, record) => record[dataIndex].indexOf(value) === 0;
    }

    return item;
  });

  const onChange = (pagination, filters, sorter, extra) => {
    setQty(extra.currentDataSource.length);
  };

  const tableProps = {
    pagination: { pageSize: 50 },
    columns: dbColumns,
    dataSource: filteredData,
    onChange: onChange,
  };

  if (expandableComponent) {
    tableProps.expandable = {
      expandedRowRender: (record) => expandableComponent(record)
    };
    tableProps.rowExpandable = () => true;
  }

  const filterData = (e) => {
    const v = String(e.target.value).trim();

    if (v) {
      const newData = data.filter(item => {
        let index = 0;
        let works = false;

        while (index < filterableProps.length && !works) {
          works = String(item[filterableProps[index]]).toUpperCase().includes(v.toUpperCase());
          index++;
        }

        return works;
      });

      setFilteredData(newData);
    } else {
      setFilteredData(data);
    }
  };

  return (
    <>
      <Typography.Title level={2}>
        {title}
        <span className="table-items-qty">({qty || data?.length})</span>
        { filterableProps.length ? <Input placeholder={`Search by ${filterableProps.join(', ')}`} type="text" onChange={filterData}/> : <></> }
      </Typography.Title>
      <Table sortDirections={['ascend', 'descend']} {...tableProps} />
    </>
  );
};

export default QueryTable;
