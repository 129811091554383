import { Badge, Layout, Menu, Tag } from "antd";
import { Link } from "react-router-dom";
import {
  getConfig,
  logout,
  selectConfigFetched,
  selectUser,
} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LoggedIn.module.css";
import logo from "../../brand/Isologotype/samu_white_dark_bckgrnd.png";
import ManagerComponent from "../session/ManagerComponent";
import { useEffect } from "react";

const { Header, Footer } = Layout;

const  __ = (k) => k;

const LoggedIn = ({ children }) => {
  const user = useSelector(selectUser);
  const isConfigFetched = useSelector(selectConfigFetched);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isConfigFetched) {
      dispatch(getConfig());
    }
  }, [isConfigFetched]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const initials = (name) => {
    const initials = name
      .split(" ")
      .map((w) => w.charAt(0))
      .join("");

    return <span className={styles.initialsBuble}>{initials}</span>;
  };

  const userIcon = (user) => {
    return initials(user.name);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="header">
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={[]}>
          <Menu.Item
            key="logo"
            style={{ fontFamily: "secular" }}
            className={styles.logoMenuItem}
          >
            <Link to="/">
              <img src={logo} alt={__("appName")} style={{ width: "135px" }} />
            </Link>
          </Menu.Item>
          <Menu.Item key="users">
            <Link to="users">Users</Link>
          </Menu.Item>
          <Menu.Item key="accounts">
            <Link to="accounts">Accounts</Link>
          </Menu.Item>
          <Menu.Item key="analytics">
            <Badge.Ribbon text="Nuevo" className={styles.newMenuItem}>
              <Link to="analytics">{__("analytics.label")}</Link>
            </Badge.Ribbon>
          </Menu.Item>
          <Menu.Item key="library">
            <Link to="library">{__("library.label")}</Link>
          </Menu.Item>
          <Menu.Item key="agenda">
            <Link to="agenda">{__("agenda.label")}</Link>
          </Menu.Item>
          <Menu.Item key="account" style={{ position: "absolute", right: 0 }}>
            <Menu.SubMenu
              key="SubMenu"
              title={user && user.name}
              icon={userIcon(user)}
            >
              <Menu.Item key="profile">
                <Link to="profile">{__("menu.profile")}</Link>
              </Menu.Item>
              <ManagerComponent>
                <Menu.Item key="companySettings">
                  <Link to="company-settings">{__("menu.settings")}</Link>
                </Menu.Item>
              </ManagerComponent>
              <Menu.Item key="two" onClick={handleLogout}>
                {__("menu.logout")}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.Item>
        </Menu>
      </Header>
      {children}
      <Footer
        style={{
          textAlign: "center",
          background: "#1791ff",
          color: "#fff",
          borderTop: "1px solid #ddd",
        }}
      >
        {__("appName")} © 2023
      </Footer>
    </Layout>
  );
};

export default LoggedIn;
