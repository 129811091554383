import React from "react";
import QueryTable from "../queryTable/Index";
import moment from "moment";

const AccountList = () => {
  const columns = [
    {
      title: "_id",
      dataIndex: "_id",
      search: true
    },
    {
      title: "Name",
      dataIndex: "name",
      search: true
    },
    {
      title: "Meetings Qty",
      dataIndex: "meetingsQty"
    },
    {
      title: "Last recorded meeting",
      dataIndex: "lastMeetingCreatedAt",
      mapper: ({ lastMeetingCreatedAt }) => lastMeetingCreatedAt ? moment(lastMeetingCreatedAt).format() : '',
      sorter: {
        compare: (a, b, c, d) => {
          if (a.lastMeetingCreatedAt && b.lastMeetingCreatedAt) {
            return moment(a.lastMeetingCreatedAt).isSameOrBefore(moment(b.lastMeetingCreatedAt)) ? -1 : 1;
          } else if (!a.lastMeetingCreatedAt) {
            return -1;
          } else if (!b.lastMeetingCreatedAt) {
            return 1;
          }
        }
      }
    },
  ];

  const aggregate = [
    {
      $lookup: {
        from: 'meetings',
        localField: '_id',
        foreignField: 'account',
        as: 'meetings'
      }
    },
    {
      $addFields: {
        meetingsQty: {
          $size: {
            $filter: {
              input: '$meetings',
              as: 'meeting',
              cond: {
                $and: [
                  { $eq: ['$$meeting.status', 'COMPLETED'] },
                  { $gt: ['$$meeting.duration', 5] } // Assuming 'duration' is in minutes
                ]
              }
            }
          }
        }
      }
    },
    {
      $project: {
        _id: 1,
        name: 1,
        meetingsQty: 1,
        lastMeetingCreatedAt: {
          $cond: {
            if: { $gt: ['$meetingsQty', 0] },
            then: { $arrayElemAt: ['$meetings.createdAt', -1] },
            else: null
          }
        }
      }
    }
  ]

  return <div style={{ padding: "25px"}}>
    <QueryTable title={'Accounts'} collection={'accounts'} query={aggregate} operation={'aggregate'} columns={columns}/>
  </div>
};

export default AccountList;
